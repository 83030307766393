import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string;
  isSendResetLink: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  isMinLength: boolean;
  newPassword: string;
  confirmPassword: string;
  confirmPasswordError: string;
  newPasswordError: string;
  newPasswordHide: boolean;
  confirmPasswordHide: boolean;
  token: string;
  activeMenu: string;
  drawerOpen: boolean;
  emailError: string;
  emailErrorMain: string;
  emailLinkMsg: string;
  resetErrorMsg: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordCallId: string = "";
  resetPasswordCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      email: "",
      isSendResetLink: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      isMinLength: false,
      newPassword: "",
      confirmPassword: "",
      confirmPasswordError: "",
      newPasswordError: "",
      newPasswordHide: false,
      confirmPasswordHide: false,
      token: "",
      activeMenu: "1",
      drawerOpen: false,
      emailError: "",
      emailErrorMain: "",
      emailLinkMsg: "",
      resetErrorMsg: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.forgotPasswordCallId:
          this.ForgotPasswordApiCall(responseJson);
          break;

        case this.resetPasswordCallId:
          this.ResetPasswordApiCall(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const email = params.get("email");

    if (token && email) {
      this.setState({ email, token });
    }
  }

  ForgotPasswordApiCall = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        emailLinkMsg: responseJson.message,
        isSendResetLink: true,
      });
    }
    if (responseJson.errors) {
      this.setState({
        emailErrorMain: responseJson.errors[0].account,
      });
    }
  };

  ResetPasswordApiCall = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ resetErrorMsg: responseJson.errors[0].token });
    }
    if (responseJson.data) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData" ? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
     : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  validateEmail = (email: string) => {
    const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/.test(
      email
    );
    return isValid;
  };

  handleEmailChange = (event: any) => {
    const newEmail = event.target.value;
    this.setState({ email: newEmail, emailError: "" });
  };

  forgotPasswordHandler = async () => {
    const { email, emailError } = this.state;
    if (!email) {
      this.setState({ emailError: "Email is required" });
      return;
    }

    if (!this.validateEmail(this.state.email)) {
      this.setState({ emailError: "Invalid email" });
      return;
    }

    if (emailError) return;

    const body = {
      data: {
        email: this.state.email,
      },
    };

    this.forgotPasswordCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_forgot_password/passwords/send_mail",
      contentType: "application/json",
      body,
    });
  };

  validatePassword = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isMinLength = password?.length >= 8;

    this.setState({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isMinLength,
    });
  };

  handleNewPasswordChange = (event: any) => {
    const newPassword = event.target.value;
    const confirmPassword = this.state.confirmPassword;
    if (this.state.confirmPassword !== "") {
      this.validatePasswords1(newPassword, confirmPassword);
    }
    this.setState({ newPassword, newPasswordError: "" });
    this.validatePassword(newPassword);
  };

  handleConfirmPasswordChange = (event: any) => {
    const confirmPassword = event.target.value;
    const newPassword = this.state.newPassword;
    this.validatePasswords1(newPassword, confirmPassword);
    this.setState({ confirmPassword });
  };

  validatePasswords1 = (newPassword: string, confirmPassword: string) => {
    const confirmPasswordError =
      newPassword !== confirmPassword ? "Password doesn't match" : "";
    this.setState({
      confirmPasswordError,
    });
  };

  checkEmptyFields = () => {
    const { newPassword, confirmPassword } = this.state;
    let isValid = true;
    if (!newPassword) {
      this.setState({
        newPasswordError: "New password is required",
      });
      isValid = false;
    }

    if (!confirmPassword) {
      this.setState({
        confirmPasswordError: "Confirm password is required",
      });
      isValid = false;
    }

    return isValid;
  };

  handleNewPasswordHide = () => {
    this.setState({ newPasswordHide: !this.state.newPasswordHide });
  };

  handleConfirmNewPasswordHide = () => {
    this.setState({ confirmPasswordHide: !this.state.confirmPasswordHide });
  };

  resetPasswordHandler = async () => {
    if (!this.checkEmptyFields()) return;
    if (this.state.newPasswordError || this.state.confirmPasswordError) return;
    const { hasLowerCase, hasNumber, hasUpperCase, isMinLength } = this.state;
    if (!hasUpperCase || !hasLowerCase || !hasNumber || !isMinLength) {
      this.setState({ newPasswordError: configJSON.passwordError });
      return;
    }

    const body = {
      token: this.state.token,
      data: {
        new_password: this.state.confirmPassword,
      },
    };

    this.resetPasswordCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_forgot_password/passwords",
      contentType: "application/json",
      body,
    });
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  // Customizable Area End
}
