import React from "react";

// Customizable Area Start
import {
  Modal,
  Box,
  Button,
  Typography,
  Input,
  InputBase
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
const AvatarIcon = require("../assets/avatar.jpg")
const uploadFile = require("../assets/uploadFile.svg")
const emojiIcon = require("../assets/emojiIcon.svg")
const sendMessage = require("../assets/sendMessage.svg")
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomMenu from "../../../components/src/CustomMenuItem.web";
import { backButton, profileImg } from "./assets";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomModal from "../../../components/src/CustomModal.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, styled } from "@material-ui/core";
import Toast from "../../../components/src/CustomSnackbar.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ViewChatController, {
  configJSON,
  IMessage,
  Props,
} from "./ViewChatController";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddAccountModal = () => (
    <Modal
      open={this.state.isVisibleModal}
      onClose={this.hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={webStyles.modalContainer}>
        <Input
          data-test-id={"inputAccountID"}
          placeholder={configJSON.accountIdPlaceholder}
          onChange={(event) =>
            this.handleAccountIdInputChange(event?.target?.value ?? "2")
          }
        />
        <Box sx={webStyles.modalButtonView}>
          <Button
            data-test-id="btnAddAccount"
            variant="contained"
            color="primary"
            onClick={() =>
              this.addUserToChat(this.state.accountIdInput, this.state.chatId)
            }
          >
            {configJSON.addButtonText}
          </Button>
        </Box>
        <Box sx={webStyles.modalButtonView}>
          <Button
            data-test-id="btnCloseModal"
            variant="contained"
            color="primary"
            onClick={() => this.hideModal()}
          >
            {configJSON.closeButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  imagePriviewModal = () => (
    <Modal
      open={this.state.isVisiblePreviewModal}
      onClose={this.hidePreviewModal}
    >
      <Box sx={webStyles.previewModalContainer}>
        <img src={this.state.imageUrl} style={webStyles.previewImage} />
        <Box sx={webStyles.previewModalButtons}>
          <Box sx={webStyles.modalButtonView}>
            <Button
              data-test-id="btnClosePreviewModal"
              variant="contained"
              color="secondary"
              onClick={() => this.hidePreviewModal()}
            >
              {configJSON.cancelText}
            </Button>
          </Box>
          <Input
            data-test-id={"inputImageMessage"}
            placeholder={configJSON.writeMessagePlaceholder}
            style={webStyles.previewInput}
            value={this.state.message}
            fullWidth={true}
            onChange={(event) =>
              this.handleMessageChange(event?.target?.value ?? "Hi")
            }
          />
          <Box sx={webStyles.modalButtonView}>
            <Button
              data-test-id="btnSendImageMessage"
              variant="contained"
              color="primary"
              disabled={this.state.message?.length === 0}
              onClick={this.handleSendMessage}
            >
              {configJSON.sendText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  renderMessages = () => {
    const { chatData } = this.state;
    const messages = chatData?.attributes?.messages ?? [];

    return (
      <Box sx={{marginBottom: '40px'}} data-test-id="messageBoxContainer">
        {messages.map((item: IMessage, index: number) => (
            <Box
              key={`message-${index}`}
              sx={webStyles.messageBoxContainer}
            >
              <Box sx={{ marginRight: '16px'}}>
                {AvatarIcon ? (
                  <img src={AvatarIcon} alt="name" style={{ width: '44px', height: '44px', borderRadius: '50%' }} />
                ) : (
                  <Box sx={webStyles.avatarCircle}>
                    H
                  </Box>
                )}
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={{fontSize: '14px', fontWeight: '700', marginBottom: '4px'}}>Hellen Whilliams</Typography>
                <Typography style={{fontSize: '14px'}}>{item.attributes?.message}</Typography>
                {item.attributes.attachments ? (
                  <img
                    src={item.attributes.attachments[0].url}
                    style={webStyles.previewImage}
                  />
                ) : null}
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  renderDropDownMenu = () => {
    return (
      <div>
        <MoreHorizIcon
          style={{ cursor: "pointer" }}
          onClick={this.handleClickOptions}
          data-test-id="handleClickOptions"
        />
        <CustomMenu
          open={this.state.openChatOptions}
          onClose={this.handleCloseOptions}
          anchorEl={this.state.anchorElCoverOptions}
          options={this.menuOptionsOptions}
          position="right"
          data-test-id="handleCloseOptions"
        />
      </div>
    );
  };

  renderViewContact = () => {
    const { showDataCount, mediaData } = this.state;
    const isAllDataShown = showDataCount >= mediaData.length;
    return (
      <Box sx={{ borderRight: `1px solid ${customTheme.palette.grey.light}` }}>
        <Box sx={webStyles.headerViewContact}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
            }}
          >
            <img
              src={backButton}
              alt="backIcon"
              onClick={this.handleBackArrow}
              data-test-id="handleBackArrow"
            />
            <div style={{ ...CustomFontVariant.palette.font20700 }}>
              Contact
            </div>
          </div>
        </Box>
        <Grid container spacing={6} style={{ paddingInline: 40 }}>
          <Grid item md={6}>
            <ProfileImage src={profileImg} alt="profileImg" />
            <Title>Matt Taylor</Title>
            <Description>metttaylor@mail.com</Description>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Description>
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
                marginTop: 40,
              }}
            >
              <ButtonContained onClick={this.handleUnfriendModal}>
                Unfriend
              </ButtonContained>
              <ButtonContainedRed onClick={this.handleBlockAccountModal}>
                Block
              </ButtonContainedRed>
              <ButtonContainedRed onClick={this.handleReportModal}>
                Report
              </ButtonContainedRed>
            </div>
          </Grid>
          <Grid item md={6}>
            <Title1>Media:</Title1>
            <ContainerMain>
              {this.filesData
                ?.slice(0, this.state.showDataCount)
                .map((src: any, index) => (
                  <StyledImage
                    key={index}
                    src={src.src}
                    alt={`photo-${index}`}
                    data-test-id="handleOpenImageModal"
                  />
                ))}
            </ContainerMain>
            <ShowMoreButton
              disabled={isAllDataShown}
              onClick={this.handleShowMore}
              data-test-id="handleShowMore"
            >
              show more
            </ShowMoreButton>
            <Title2>Mutual Groups:</Title2>
            <div style={{ maxHeight: 268, overflowY: "auto" }}>
              {this.chatMessages.map(
                ({ id, imgSrc, designMessage, userMessage }) => (
                  <Parent key={id}>
                    <Img src={imgSrc} alt="profile" />
                    <div>
                      <div style={{ ...CustomFontVariant.palette.font14700 }}>
                        {designMessage}
                      </div>
                      <EllipseMsg>{userMessage}</EllipseMsg>
                    </div>
                  </Parent>
                )
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
        {this.state.viewContact ? (
           this.renderViewContact()
          ) :
          <Box sx={webStyles.container}>

            <Box sx={webStyles.header}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{ marginRight: '6px'}}>
                  {AvatarIcon ? (
                    <img src={AvatarIcon} alt='avatar' style={{ width: '44px', height: '44px', borderRadius: '50%' }} />
                  ) : (
                    <Box sx={webStyles.avatarCircle}>
                      M
                    </Box>
                  )}
                </Box>
                <Typography style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '22px',
                    color: '#0F172A',
                  }}>
                    Matt Taylor
                </Typography>
              </Box>
              {this.renderDropDownMenu()}
            </Box>

            <Box sx={webStyles.innerContainer}>
              
              {this.renderMessages()}

              <Box sx={webStyles.inputContainer}>
                  <Box sx={webStyles.iconContainer}>
                    <img src={uploadFile} alt="Upload File" style={webStyles.iconStyle} />
                    <img src={emojiIcon} alt="Emoji" style={webStyles.iconStyle} />
                  </Box>
                  <InputBase
                    data-test-id="inputMessage"
                    placeholder="Message Matt"
                    sx={webStyles.messageInput}
                  />
                  <Button style={webStyles.sendMessageButton} data-test-id="btnSendMessage">
                    <img src={sendMessage} alt="Send message" style={{width: '24px', height: '24px'}}/>
                  </Button>
              </Box>
            </Box>

          </Box>
          }
         <CustomModal
            open={this.state.unfriend}
            data-test-id="handleUnfriendModal"
            cancelButtonText="Cancel"
            doneButtonText="Unfriend"
            onCancel={this.handleUnfriendModal}
            onDone={this.handleUnfriendModal}
            title="Unfriend"
            subtitle="Are you sure you want to unfriend Matt Taylor?"
          />
          <CustomModal
            open={this.state.blockAccount}
            data-test-id="handleBlockAccountModal"
            cancelButtonText="Cancel"
            doneButtonText="Block"
            onCancel={this.handleBlockAccountModal}
            onDone={this.handleBlockAccountModal}
            title="Block Account"
            subtitle="Are you sure you want to block Matt Taylor?"
          />
          <CustomModal
            open={this.state.reportModalOpen}
            data-test-id="handleReportModal"
            cancelButtonText="Cancel"
            doneButtonText="Submit"
            onCancel={this.handleReportModal}
            onDone={this.handleReportModalDone}
            title="Report"
            subtitle="You are reporting Matt Taylor for:"
          >
            <div
              style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                marginTop: 24,
              }}
            >
              <Label>Reason</Label>
              <CustomFlagDropdown isOpen={this.state.openReportMenu}>
                <input
                  type="text"
                  placeholder="select reason"
                  value={this.state.reportReason}
                  onClick={this.handleMenuItem}
                  data-test-id="handleMenuItem"
                />
                {this.state.reasonError && (
                 <ErrorMsg color="error">{this.state.reasonError}</ErrorMsg>
                )}
                <KeyboardArrowDownIcon className="downIcon" />
                <Box className="flagItem">
                  {this.reportData.map((item, index) => (
                    <TypographyItem
                      key={index}
                      onClick={() => this.handleSelectReason(item.report)}
                      data-test-id="handleSelectReason"
                    >
                      {item.report}
                    </TypographyItem>
                  ))}
                </Box>
              </CustomFlagDropdown>
            </div>
          </CustomModal>
          <Toast
            open={this.state.blockToastOpen}
            message={this.state.blockToastMsg}
            severity={"success"}
            onClose={this.handleCloseBlockToast}
            duration={3000}
            position={{ vertical: "bottom", horizontal: "center" }}
            data-test-id="handleCloseBlockToast"
          />
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: '1px solid #CBD5E1',
    height: '100%',
  },
  header: {
    display: "flex",
    width: '100%',
    borderBottom: "1px solid #CBD5E1",
    justifyContent: "space-between",
    padding: '17px 48px',
    alignItems: "center",
    backgroundColor: '#FFFCF3',
    boxSizing: 'border-box',
    marginBottom: '35px'
  },
  headerButtonView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  avatarCircle: {
    fontFamily: 'Open Sans',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    backgroundColor: '#EAE7DC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#000',
    border: '1px solid #EAE7DC',
  },
  innerContainer: {
    padding: '0 48px',
    width: '100%',
    boxSizing: 'border-box'
  },
  inputContainer: {
    padding: '6px',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '48px'
  },
  iconContainer: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    padding: '6px',
    paddingRight: '0px'
  },
  iconStyle: {
    width: '32px',
    height: '32px',
  },
  messageInput: {
    fontFamily: 'Open Sans',
    flex: 1,
    padding: '6px 12px',
    border: 'none',
    '&::placeholder': {
      fontSize: '16px',
      color: '#64748B',
    },
  },
  sendMessageButton: {
    padding: '10px',
    backgroundColor: '#EAE7DC',
    borderRadius: '8px',
    minWidth: 'auto'
  },
  messageBoxContainer: {
    display: "flex",
    marginBottom: '16px',
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewModalContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as "absolute",
    width: '80%',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewImage: {
    marginTop: '16px',
    width: "270px",
    height: "auto",
    borderRadius: '8px',
  },
  previewInput: {
    marginLeft: 10,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  previewModalButtons: {
    margin: 10,
    display: "flex",
    flexDirection: "row",
  },
  headerViewContact: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #CBD5E1",
    justifyContent: "space-between",
    padding: "17px 16px",
    alignItems: "center",
    backgroundColor: "#FFFCF3",
    boxSizing: "border-box",
    marginBottom: "35px",
  },
};

const ProfileImage = styled("img")({
  maxWidth: "320px",
  width: "100%",
  aspectRatio:"1 / 1",
  borderRadius: "50px",
  objectFit: "cover",
});

const Title = styled("div")({
  ...CustomFontVariant.palette.font20700,
  marginTop: 16,
});

const Title1 = styled("div")({
  ...CustomFontVariant.palette.font14700,
  marginBottom: 16,
});

const Title2 = styled("div")({
  ...CustomFontVariant.palette.font14700,
  margin: "16px 0px",
});

const Description = styled("div")({
  ...CustomFontVariant.palette.font16400,
  marginTop: 16,
});

const ButtonContained = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  borderRadius: 8,
});

const ButtonContainedRed = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.red.secondary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  borderRadius: 8,
});

const ContainerMain = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(104px, 1fr))",
  columnGap: "16px",
  rowGap: "12px",
  width: "100%",
  marginTop: 12,
  overflow: "auto",
  maxHeight: 192,
});

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
  objectFit: "cover",
  aspectRatio: "4 / 3",
});

const Parent = styled("div")({
  display: "flex",
  gap: 16,
  alignItems: "center",
  marginBottom: 12,
});

const Img = styled("img")({
  width: "44px",
  height: "44px",
  objectFit: "cover",
  borderRadius: 12,
});

const ShowMoreButton = styled(Box)(({ disabled }: any) => ({
  marginTop: 10,
  color: customTheme.palette.grey.extraLight,
  textTransform: "none",
  display: disabled ? "none" : "block",
  ...CustomFontVariant.palette.font16700,
  cursor: "pointer",
}));

const Label = styled("label")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
});

const CustomFlagDropdown = styled(Box)(({ isOpen }: any) => ({
  position: "relative",
  "& .downIcon": {
    position: "absolute",
    right: 10,
    top: 10,
  },
  "& input": {
    width: "100%",
    height: 44,
    border: `1px solid ${customTheme.palette.grey.light}`,
    borderRadius: 8,
    padding: "10px 28px 10px 8px",
    "&::placeholder": {
      color: "gray",
    },
    "&:focus-visible": {
      border: `1px solid ${customTheme.palette.grey.light}`,
      outline: "none",
    },
  },
  "& .flagItem": {
    position: "absolute",
    left: 0,
    top: 44,
    boxShadow: "0px 2px 4px 0px #00000026",
    borderRadius: 8,
    display: isOpen ? "block" : "none",
    background: customTheme.palette.white.main,
    maxWidth: 452,
    width: "100%",
    padding: 10,
    zIndex: 9999999,
    "& p": {
      borderRadius: 8,
      padding: "8px 16px",
      fontSize: 16,
      cursor: "pointer",
    },
    "& p:hover": {
      backgroundColor: customTheme.palette.grey.primary,
    },
    maxHeight: "30vh",
    overflowY: "auto",
  },
}));

const TypographyItem = styled(Typography)({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.bold,
});

const EllipseMsg = styled("div")({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 200,
  ...CustomFontVariant.palette.font14700,
});

const ErrorMsg = styled(Typography)({
  fontSize: "14px !important",
  fontWeight: 400,
  fontFamily: "Open Sans !important",
  color: `${customTheme.palette.red.primary}!important`,
  marginTop: "4px !important",
});
// Customizable Area End
