export const coverProfile = require("../assets/coverProfile.png");
export const profile = require("../assets/profile.png");
export const editIcon = require("../assets/editIcon.png");
export const vector = require("../assets/Vector.png");
export const rightArrow = require("../assets/rightArrow.png");
export const profilePlaceholder = require("../assets/placeholder.png");
export const back = require("../assets/back.png");
export const deleteButton = require("../assets/deleteButton.png");
export const location = require("../assets/location.png");
export const action = require("../assets/action.png");
export const image1 = require("../assets/image_1.png");
export const image2 = require("../assets/image_2.png");
export const image3 = require("../assets/image_3.png");
export const image4 = require("../assets/image_4.png");
export const image5 = require("../assets/image_5.png");
export const coverPicture = require("../assets/coverPicture.png");
export const search = require("../assets/search.png");