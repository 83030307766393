import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  friendDetails: {
    friendId: number | null;
    name: string;
    bio: string;
    profile_image: string;
    profile_cover: string;
    email: string;
    mutual_friends: [];
    status: string | null;
  };
  connectionId: number | null;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FriendsProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFriendProfileCallId: string = "";
  unFriendCallId: string = "";
  friendCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      friendDetails: {
        friendId: null,
        name: "",
        bio: "",
        profile_image: "",
        profile_cover: "",
        email: "",
        mutual_friends: [],
        status: "",
      },
      connectionId: null,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getFriendProfileCallId) {
        if (responseJson.data) {
          const {
            email,
            bio,
            profile_image,
            profile_cover,
            name,
            mutual_friends,
            connection_id,
          } = responseJson.data.attributes;
          this.setState({
            friendDetails: {
              friendId: connection_id,
              name,
              email,
              bio,
              profile_image,
              profile_cover,
              mutual_friends: mutual_friends.data,
              status: connection_id,
            },
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const connectionId = await getStorageData("connectionId");
    this.setState({ connectionId });
    this.handleGetFriendProfile(connectionId);
  }

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
    request.addData(  getName(MessageEnum.RestAPIResponceEndPointMessage),  endPoint );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData" ? request.addData( getName(MessageEnum.RestAPIRequestBodyMessage),  JSON.stringify(body) ) : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleNavigation = () => {
    window.history.back();
  };

  handleNavigation1 = (path: string) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleGetFriendProfile = async (connectionId: number | null) => {
    this.getFriendProfileCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_addfriends/connections/show_friend_details?connection_id=${connectionId}`,
      contentType: "application/json",
    });
  };

  // Customizable Area End
}
