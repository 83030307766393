import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { URLSearchParams } from "url";
import React from "react";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  searchText: string;
  searchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  activeMenu: string;
  drawerOpen: boolean;
  recentlySerchedData: [];
  storeLocation: string;
  address: string;
  lat: number;
  lng: number;
  markerPosition: any;
  open: boolean;
  value: number;
  selectedTab: number;
  gmLocation: {};
  suggestions: [];
  searchValue: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchApiCallId: any;
  inputRef: google.maps.places.SearchBox | null = null;
  getPreviousSearchCallId: string = "";
  addPreviousSearchCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      searchText: "",
      searchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      activeMenu: "1",
      drawerOpen: false,
      recentlySerchedData: [],
      storeLocation: "",
      address: "",
      lat: 37.7749,
      lng: -122.4194,
      markerPosition: 0,
      open: false,
      value: 0,
      selectedTab: 0,
      gmLocation: {},
      suggestions: [],
      searchValue: "",
      // Customizable Area End
    };
    // Customizable Area Start
    // this.inputRef = React.createRef<google.maps.places.SearchBox>();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getPreviousLocation();
    this.handleInputChange = this.handleInputChange.bind(this);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getPreviousSearchCallId:
          this.getPreviousSearchCallIdApi(responseJson);
          break;

        case this.addPreviousSearchCallId:
          this.addPreviousSearchCallIdApi(responseJson);
          break;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getPreviousSearchCallIdApi = (responseJson: any) => {
    if (responseJson.previous_search) {
      this.setState({ recentlySerchedData: responseJson.previous_search });
    } else if (responseJson.errors[0].token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  addPreviousSearchCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.handleNavigation("Cfargooglestreetviewinlaycontent");
    }
  };

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleNavigation = (path: string) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  txtInputSearchTextProps = {
    onChangeText: (text: string) => {
      this.setState({ searchText: text });
    }
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  getSearchList = (token: string) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      query: this.state.searchText
    };

    this.searchApiCallId = requestMessage.messageId;
    let urlParams = attrs?.toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePlaceChanged = () => {
    const places = this.inputRef?.getPlaces() || [];
    const [place] = places;
    if (place) {
      if (place.geometry && place.geometry.location) {
        setStorageData("lat", place.geometry.location.lat());
        setStorageData("lng", place.geometry.location.lng());
        const address = place.formatted_address;
        this.addPreviousLocation(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          address
        );
      }
    } else {
      console.warn("No place found.");
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (!input) {
      this.setState({ suggestions: [], searchValue: "" });
      return;
    }
    this.setState({ searchValue: event.target.value });
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input }, (predictions, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        predictions
      ) {
        this.setState({ suggestions: predictions as unknown as [] });
      } else {
        this.setState({ suggestions: [] });
      }
    });
  };

  getPreviousLocation = async () => {
    this.getPreviousSearchCallId = await this.apiCall({
      method: "GET",
      endPoint: "bx_block_location/locations/get_previous_search",
      contentType: "application/json",
    });
  };

  addPreviousLocation = async (
    lat: number,
    lng: number,
    address: string | undefined
  ) => {
    const body = {
      previous_search: {
        latitude: lat,
        longitude: lng,
        address,
      },
    };
    this.addPreviousSearchCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_location/locations/previous_search",
      contentType: "application/json",
      body,
    });
  };

  previousSearchRedirect = async (lat: number, lng: number) => {
    await setStorageData("lat", lat);
    await setStorageData("lng", lng);
    this.handleNavigation("Cfargooglestreetviewinlaycontent");
  };
  // Customizable Area End
}
