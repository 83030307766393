import React from "react";

// Customizable Area Start
import { styled, Button, Tab, Tabs } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { back, search } from "./assets";
import Header from "../../../blocks/landingpage/src/Header.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";

interface Friend {
  attributes: {
    id: number;
    photo: string;
    name: string;
    mutual_friend: number;
  };
}
// Customizable Area End

import FriendsListController, {
  Props,
  configJSON,
} from "./FriendsListController";

export default class FriendsList extends FriendsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header activeTab="3" navigation={this.props.navigation} id={""} />
        <Main2>
          <Back2
            data-test-id="handleNavigation"
            onClick={() => this.handleNavigation("Customisableuserprofiles")}
            src={back}
          ></Back2>
          <MainContainer2>
            <MainSelection2>
              <Heading2>{configJSON.Friends}</Heading2>
              <SearchSection2>
                <TabMain2>
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleTabChange}
                    TabIndicatorProps={{ color: "transparent" }}
                    data-test-id="handleTabChange"
                  >
                    <CustomTab2
                      label="All friends"
                      selected={this.state.tabValue === 0}
                    />
                    <CustomTab2
                      label="Recently added"
                      selected={this.state.tabValue === 1}
                    />
                  </Tabs>
                </TabMain2>
                <SearchInputParent2>
                  <img src={search} alt="search" style={{ paddingRight: 8 }} />
                  <SearchInput2
                    type="search"
                    placeholder="Search friend"
                    value={this.state.search}
                    onChange={this.handleSearch}
                    data-test-id="handleSearch"
                  ></SearchInput2>
                </SearchInputParent2>
              </SearchSection2>
              {this.state.FriendsList.map((object: Friend, index) => (
                <div style={{ marginTop: 16 }}>
                  <PrifileMain2
                    style={{ padding: "12px 0px" }}
                    key={index}
                    onClick={() => {
                      this.handleNavigation(
                        "FriendsProfile",
                        object.attributes.id
                      );
                    }}
                  >
                    <FriendProfileMain2>
                      <FriendProfileImg2 src={object.attributes.photo} />
                      <FriendInner2>
                        <FriendProfileName2>
                          {object.attributes.name}
                        </FriendProfileName2>
                        <FriendProfileMutual2>
                          {object.attributes.mutual_friend}
                        </FriendProfileMutual2>
                      </FriendInner2>
                    </FriendProfileMain2>
                    <ButtonContained2 variant="outlined">
                      Message
                    </ButtonContained2>
                  </PrifileMain2>
                  <Divider2 />
                </div>
              ))}
              {this.state.totalCount > 1 && (
                <CustomPagination2
                  count={this.state.totalCount}
                  variant="text"
                  siblingCount={0}
                  page={this.state.currentPage}
                  onChange={this.handlePageChange}
                  data-test-id="handlePageChange"
                />
              )}
            </MainSelection2>
          </MainContainer2>
        </Main2>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Main2 = styled("div")({
  padding: "40px 36px",
});

const MainContainer2 = styled("div")({
  borderRadius: 24,
  boxShadow: `
      0px 8px 32px 0px #0000000F,
      0px 4px 8px 0px #00000008,
      0px 25px 50px 0px #00000017
    `,
});

const Back2 = styled("img")({
  marginBottom: 20,
  cursor: "pointer",
});

const MainSelection2 = styled("div")({
  paddingInline: 36,
  padding: "40px 0px",
});

const Heading2 = styled("div")({
  ...CustomFontVariant.palette.font30700,
});

const PrifileMain2 = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ButtonContained2 = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
});

const FriendProfileMain2 = styled("div")({
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
});

const FriendProfileName2 = styled("div")({
  ...CustomFontVariant.palette.font20400,
});

const FriendProfileMutual2 = styled("div")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.main,
});

const FriendProfileImg2 = styled("img")({
  width: 56,
  height: 56,
  borderRadius: "50%",
});

const FriendInner2 = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingLeft: 10,
});

const SearchSection2 = styled("div")({
  display: "flex",
  gap: 18,
  margin: "46px 0px",
});

const TabMain2 = styled("div")({
  backgroundColor: customTheme.palette.grey.mintGrey,
  display: "flex",
  alignItems: "center",
  padding: 4,
  borderRadius: 50,
});

const SearchInputParent2 = styled("div")({
  width: "100%",
  borderRadius: 50,
  border: `1px solid ${customTheme.palette.grey.light}`,
  display: "flex",
  alignItems: "center",
  padding: 8,
});

const SearchInput2 = styled("input")({
  border: "none",
  width: "100%",
  "&:focus": {
    outline: "none",
  },
  ...CustomFontVariant.palette.font16400,
});

const CustomTab2 = styled(Tab)(({ selected }: { selected: boolean }) => ({
  color: selected
    ? `${customTheme.palette.black.primary}`
    : `${customTheme.palette.grey.extraCoolGrey}`,
  backgroundColor: selected
    ? `${customTheme.palette.grey.primary}`
    : `transparent`,
  borderRadius: 50,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
}));

const Divider2 = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const CustomPagination2 = styled(Pagination)({
  display: "flex",
  justifyContent: "center",
  marginTop: 24,
  "& .Mui-selected": {
    ...CustomFontVariant.palette.font16700,
  },
  "& .MuiPaginationItem-root": {
    color: customTheme.palette.grey.extraCoolGrey,
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "unset",
    color: customTheme.palette.black.primary,
  },
  "& .MuiSvgIcon-root": {
    color: customTheme.palette.grey.secondary,
  },
});

// Customizable Area End
