import React from "react";
// Customizable Area Start
import Header from "../../../blocks/landingpage/src/Header.web";
import { styled, Typography } from "@material-ui/core";
import Search from "../../../blocks/search/src/Search.web";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header activeTab="1" navigation={this.props.navigation} id={""} />
        <Search navigation={this.props.navigation} id={""} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Main = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  marginTop: 50,
});
// Customizable Area End
