import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  open: boolean;
  addressListId: number | null;
  listData: [];
  title: string;
  description: string;
  deleteLocationId: number | null;
  copyAddress: string;
  default_created: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class AddressListWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAddressListCallId: string = "";
  deleteAddressListCallId: string = "";
  deleteLocationCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      anchorEl: null,
      open: false,
      addressListId: null,
      listData: [],
      title: "",
      description: "",
      deleteLocationId: null,
      copyAddress: "",
      default_created: true,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getAddressListCallId:
          this.getAddressListCallIdApi(responseJson);
          break;

        case this.deleteAddressListCallId:
          this.deleteAddressListCallIdApi(responseJson);
          break;

        case this.deleteLocationCallId:
          this.deleteLocationCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const AddressListId = await getStorageData("AddressListId");
    this.setState({ addressListId: AddressListId });
    this.handleGetAddressList(AddressListId);
  }

  getAddressListCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      const { title, description, list_items,default_created } = responseJson.data.attributes;
      this.setState({
        listData: list_items,
        title,
        description,
        default_created : default_created
      });
    }
  };

  deleteAddressListCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.handleNavigation("Customisableuserprofiles");
    }
  };

  deleteLocationCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.handleGetAddressList(this.state.addressListId);
    }
  };

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData" ? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
    : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleNavigation = (path: string) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    deleteLocationId: number,
    copyAddress: string
  ) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
      deleteLocationId,
      copyAddress,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleRemove = (deleteLocationId: number | null) => {
    this.handleDeleteLocationChange(deleteLocationId);
    this.handleClose();
  };

  handleShareAddress = (copyAddress: string) => {
    navigator.clipboard?.writeText(copyAddress);
    this.handleClose();
  };

  handleViewHome = () => {
    this.handleClose();
  };

  menuOptions = (deleteLocationId: number | null, copyAddress: string) => [
    { label: "Remove", action: () => this.handleRemove(deleteLocationId) },
    {
      label: "Share Address",
      action: () => this.handleShareAddress(copyAddress),
    },
    { label: "View Home", action: this.handleViewHome },
  ];

  handleGetAddressList = async (addressListId: number | null) => {
    this.getAddressListCallId = await this.apiCall({
      method: "GET",
      endPoint: `account_block/lists/${addressListId}`,
      contentType: "application/json",
    });
  };

  handleDeleteAddressListAll = async (addressListId: number | null) => {
    this.deleteAddressListCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `account_block/lists/${addressListId}`,
      contentType: "application/json",
    });
  };

  handleDeleteLocationChange = async (deleteLocationId: number | null) => {
    this.deleteLocationCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `account_block/list_items/${deleteLocationId}`,
      contentType: "application/json",
    });
  };
  // Customizable Area End
}
