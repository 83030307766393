import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Reaptcha from "reaptcha";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import CaptchaController, { Props, configJSON } from "./CaptchaController.web";

export default class Captcha extends CaptchaController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <form style={{margin:0}}>
              <Reaptcha
                ref={(e) => this.recaptchaRef(e)}
                sitekey={this.state.key}
                onVerify={(recaptchaResponse: any) =>
                  this.onVerify(recaptchaResponse)
                }
                data-test-id="onVerify"
              />
            </form>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {};
// Customizable Area End
