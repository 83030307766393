import React from "react";

// Customizable Area Start
import { Box, Button, Typography, styled } from "@material-ui/core";
import Header from "../../../components/src/CustomHeader.web";
import { background, eye, eyeOff, logoMain, right, wrong } from "./assets";
import customTheme from "../../../components/src/CustomTheme.web";
import customFontVariant from "../../../components/src/CustomFontVariant.web";
// Customizable Area End

import ForgotPasswordWebController, {
  Props,
} from "./ForgotPasswordWebController";

export default class ResetPassword extends ForgotPasswordWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Wrapper>
        <Header
          navigation={this.handleNavigation}
          activeMenu={this.state.activeMenu}
          drawerOpen={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
          handleMenuClick={this.handleMenuClick}
        />
        <BackGround />
        <MainContainer>
          <Main>
            <img src={logoMain} alt="logoMain" />
            <TypoTextItem>New Password</TypoTextItem>
            {this.state.resetErrorMsg && (
              <TermError>
                <ErrorTypo>{this.state.resetErrorMsg}</ErrorTypo>
              </TermError>
            )}
            <EmailMain>
              <TypoDesc>Enter a new password for {this.state.email}</TypoDesc>
            </EmailMain>
            <EmailMain>
              <ForgotButton>Forgot Password?</ForgotButton>
              <TypoDesc1>
                No worries, we'll send a recovery link to your email.
              </TypoDesc1>
            </EmailMain>
            <EmailMain>
              <Label>New Password</Label>
              <InputWrapper>
                <Input
                  type={this.state.newPasswordHide ? "text" : "password"}
                  placeholder="Your New Password"
                  value={this.state.newPassword}
                  onChange={this.handleNewPasswordChange}
                  isError={this.state.newPasswordError}
                  data-test-id="handleNewPasswordChange"
                />
                <IconWrapper onClick={this.handleNewPasswordHide}>
                  {this.state.newPasswordHide ? (
                    <Eye src={eye} />
                  ) : (
                    <Eye src={eyeOff} />
                  )}
                </IconWrapper>
              </InputWrapper>
              {this.state.newPasswordError && (
                <ErrorMsg>{this.state.newPasswordError}</ErrorMsg>
              )}
            </EmailMain>
            <EmailMain>
              <Label>Confirm New Password</Label>
              <InputWrapper>
                <Input
                  type={this.state.confirmPasswordHide ? "text" : "password"}
                  placeholder="Confirm your new password"
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  isError={this.state.confirmPasswordError}
                  data-test-id="handleConfirmPasswordChange"
                />
                <IconWrapper onClick={this.handleConfirmNewPasswordHide}>
                  {this.state.confirmPasswordHide ? (
                    <Eye src={eye} />
                  ) : (
                    <Eye src={eyeOff} />
                  )}
                </IconWrapper>
              </InputWrapper>
              {this.state.confirmPasswordError && (
                <ErrorMsg>{this.state.confirmPasswordError}</ErrorMsg>
              )}
            </EmailMain>
            <HeadingPassword>Your password must contain</HeadingPassword>
            <div>
              <PasswordMain>
                <PasswordCheckImg
                  src={this.state.hasUpperCase ? right : wrong}
                />
                <PasswordCheck>At least one capital letter</PasswordCheck>
              </PasswordMain>
              <PasswordMain>
                <PasswordCheckImg
                  src={this.state.hasLowerCase ? right : wrong}
                />
                <PasswordCheck>At least one lowercase letter</PasswordCheck>
              </PasswordMain>
              <PasswordMain>
                <PasswordCheckImg src={this.state.hasNumber ? right : wrong} />
                <PasswordCheck>At least one number</PasswordCheck>
              </PasswordMain>
              <PasswordMain>
                <PasswordCheckImg
                  src={this.state.isMinLength ? right : wrong}
                />
                <PasswordCheck>
                  Minimum character length is 8 characters
                </PasswordCheck>
              </PasswordMain>
            </div>
            <CreateButton
              variant="contained"
              size="large"
              onClick={this.resetPasswordHandler}
              data-test-id="resetPasswordHandler"
            >
              Reset password
            </CreateButton>
          </Main>
        </MainContainer>
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const BackGround = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
  backgroundPosition: "center",
  zIndex: -1,
});

const Main = styled(Box)({
  padding: 40,
  backgroundColor: customTheme.palette.white.main,
  marginTop: "100px",
  marginBottom: "100px",
  maxWidth: "440px",
  borderRadius: 24,
  width: "100%",
});

const MainContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const TypoTextItem = styled(Typography)({
  ...customFontVariant.palette.font24700,
  marginTop: 40,
});

const EmailMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: 26,
});

const Label = styled("label")({
  ...customFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
  marginBottom: 5,
  marginTop: 16,
});

const Input = styled("input")(({ isError }: any) => ({
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const HeadingPassword = styled(Typography)({
  ...customFontVariant.palette.font16400,
  marginTop: 40,
  color: customTheme.palette.grey.bold,
});

const PasswordCheck = styled(Typography)({
  ...customFontVariant.palette.font12400,
});

const PasswordCheckImg = styled("img")({
  height: 12,
  width: 12,
});

const PasswordMain = styled("div")({
  display: "flex",
  gap: 10,
  alignItems: "center",
  marginTop: 8,
});

const CreateButton = styled(Button)({
  width: "100%",
  backgroundColor: customTheme.palette.grey.primary,
  marginTop: "40px",
  ...customFontVariant.palette.font16700,
  height: 56,
  textTransform: "none",
});

const ErrorMsg = styled(Typography)({
  ...customFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const ForgotButton = styled(Typography)({
  ...customFontVariant.palette.font18700,
  color: customTheme.palette.black.secondary,
});

const TypoDesc = styled(Typography)({
  ...customFontVariant.palette.font16400,
  color: customTheme.palette.black.secondary,
  marginTop: 8,
});

const TypoDesc1 = styled(Typography)({
  ...customFontVariant.palette.font16400,
  color: customTheme.palette.black.secondary,
  marginTop: 8,
  maxWidth: 327,
});

const IconWrapper = styled("div")({
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});

const InputWrapper = styled("div")({
  position: "relative",
  width: "100%",
});

const Eye = styled("img")({
  color: customTheme.palette.grey.extraLight,
  width: 20,
});

const TermError = styled(Box)({
  height: 60,
  width: "100%",
  backgroundColor: customTheme.palette.red.light,
  borderLeft: `4px solid ${customTheme.palette.red.primary}`,
  marginTop: 40,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
});

const ErrorTypo = styled(Typography)({
  color: customTheme.palette.red.primary,
  ...customFontVariant.palette.font12400,
  padding: "12px 16px",
  maxWidth: 328,
});

// Customizable Area End
