import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  address?: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  newPost: string;
  deleteModalOpen: boolean;
  showDataCount: number;
  locationId: number | null;
  allPost: [];
  newPostError: string;
  refresh: boolean;
  toastOpen: boolean;
  toastMessage: string;
  // Customizable Area End
}

export interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPostCallId: string = "";
  getPostApiKeyCallId: string = "";
  deletePostApiKeyCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      newPost: "",
      deleteModalOpen: false,
      showDataCount: 3,
      locationId: null,
      allPost: [],
      newPostError: "",
      refresh: false,
      toastOpen: false,
      toastMessage: "",
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.createPostCallId:
          this.createPostCallIdApi(responseJson);
          break;

        case this.getPostApiKeyCallId:
          this.getPostApiKeyCallIdApi(responseJson);
          break;

        case this.deletePostApiKeyCallId:
          this.deletePostApiKeyCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  createPostCallIdApi = (responseJson: any) => {
    if (responseJson.data.type == "error") {
      this.setState({
        newPostError: responseJson.data.attributes.errors.body[0],
      });
    } else {
      this.handleGetPost();
      this.setState({ newPost: "", newPostError: "" });
    }
  };

  getPostApiKeyCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ allPost: responseJson?.data });
    } else {
      this.setState({ allPost: [] });
    }
  };

  deletePostApiKeyCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        deleteModalOpen: false,
        toastOpen: true,
        toastMessage: responseJson.message,
      });
      this.handleGetPost();
    }
  };

  async componentDidMount() {
    const locationId = await getStorageData("locationId");
    this.setState({ locationId });
    this.handleGetPost();
  }

  apiCall = async (data: ApiCallData) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleCreatePost = async () => {
    const { newPost, locationId } = this.state;
    const body = {
      post: {
        body: newPost,
        location_id: locationId,
      },
    };

    this.createPostCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_posts/posts",
      contentType: "application/json",
      body,
    });
  };

  handleGetPost = async () => {
    const { locationId } = this.state;
    this.getPostApiKeyCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_posts/posts/?location_id=${locationId}`,
      contentType: "application/json",
    });
  };

  handleDeletePost = async (postId: number) => {
    this.deletePostApiKeyCallId = await this.apiCall({
      method: "DELETE",
      endPoint: `bx_block_posts/posts/${postId}`,
      contentType: "application/json",
    });
  };

  handleNewPostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPost = event.target.value;
    this.setState({ newPost, newPostError: "" });
  };

  handleDeleteDoneModal = () => {
    this.setState({ deleteModalOpen: true });
  };

  handleDeleteCancelModal = () => {
    this.setState({ deleteModalOpen: false });
  };

  handleShowMore = () => {
    const { showDataCount, allPost } = this.state;
    if (showDataCount < allPost.length) {
      this.setState({ showDataCount: showDataCount + 3 });
    }
  };

  handleCloseToast = () => this.setState({ toastOpen: false });

  // Customizable Area End
}
