import React from "react";

// Customizable Area Start
import {
  Modal,
  Box,
  Button,
  Typography,
  Input,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Header from "../../../blocks/landingpage/src/Header.web";
import customTheme from '../../../components/src/CustomTheme.web'; 
import ViewChat from "./ViewChat.web";

const PlusIcon = require("../assets/plus_icon.png")
const AvatarIcon = require("../assets/avatar.jpg")

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddRoomModal = () => (
    <Modal
      open={this.state.isVisibleModal}
      onClose={this.hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={webStyles.modalContainer}>
        <Input
          data-test-id={"inputRoomName"}
          placeholder={configJSON.namePlaceholder}
          onChange={(event) =>
            this.handleChatNameChange(event?.target?.value ?? 'Chat Room')
          }
        />
        <Box sx={webStyles.modalButtonView}>
          <Button
            data-test-id="createChatSubmitBtn"
            variant="contained"
            color="primary"
            onClick={() => this.createChatRoom(this.state.chatName)}
          >
            {configJSON.createButtonText}
          </Button>
        </Box>
        <Box sx={webStyles.modalButtonView}>
          <Button
            data-test-id="btnCloseModal"
            variant="contained"
            color="primary"
            onClick={() => this.hideModal()}
          >
            {configJSON.closeButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  renderRoomList = () => {
    const chatList: IChat[] = [
      {
        id: "0",
        muted: false,
        unreadCount: 2,
        lastMessage: "I’ve just received an e-mail from Necy",
        name: 'Megan Smith',
      },
      {
        id: "1",
        muted: true,
        lastMessage: "Perfect, thanks",
        name: 'Din Jackson',
      },
      {
        id: "2",
        muted: false,
        lastMessage: "Got the file, thanks",
        name: 'Sales',
      },
      {
        id: "3",
        muted: true,
        lastMessage: "Do we have a meeting tomorrow morning",
        name: 'Matt Taylor',
      },
      {
        id: "4",
        muted: false,
        lastMessage: "Got the file, thanks",
        name: 'Mary Lee',
      },
      {
        id: "5",
        muted: true,
        lastMessage: "You: Do you know where do we have breakfast",
        name: 'Christina Walsh',
      },
    ]

    return (
      <>
        {chatList.map((item: IChat, index: number) => (
          <Box
            data-test-id={`btnShowChat${index}`}
            key={`room-${index}`}
            sx={webStyles.listItemContainer}
          >

            <Box sx={{ marginRight: '12px'}}>
              {AvatarIcon ? (
                <img src={AvatarIcon} alt={item.name} style={{ width: '44px', height: '44px', borderRadius: '50%' }} />
              ) : (
                <Box sx={webStyles.avatarCircle}>
                  {item.name.charAt(0).toUpperCase()}
                </Box>
              )}
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700', fontSize: '14px', lineHeight: '22px' }}>{item.name}</Typography>
                {item.unreadCount && item.unreadCount > 0 && (
                  <Box sx={{ marginLeft: '6px', width: '10px', height: '10px', backgroundColor: '#000', borderRadius: '50%' }} />
                )}
              </Box>

              <Typography style={{ color: '#0F172A', fontSize: '14px', lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '218px' }}>
                {item.lastMessage}
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: '#64748B', fontSize: '14px', lineHeight: '22px', marginBottom: '4px' }}>3h</Typography>
              {item.unreadCount && item.unreadCount > 0 && (
                <Box sx={webStyles.unreadCount}>
                  {item.unreadCount}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <Box sx={{minHeight: '78px'}}>
              <Header activeTab="4" navigation={this.props.navigation} id={""} />
            </Box>

            <Box sx={webStyles.pageContainer}>
              <Box sx={webStyles.leftSideLayout}>
                  <Box sx={{display:"flex", justifyContent: "space-between", alignItems: "center", padding: "14px 24px", borderBottom:"1px solid #E2E8F0", marginBottom:"16px"}}>
                    <Typography style={{
                        fontWeight: 700,
                        fontSize: "30px",
                        lineHeight: "40px",
                        color: "#1E293B",
                        fontFamily: "Open Sans",
                      }}>Chat</Typography>
                    <Button
                      data-test-id={"createChatRoomBtn"}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.showModal();
                      }} 
                      style={{padding: "10px", backgroundColor: '#EAE7DC', borderRadius: '8px', minWidth: 'auto', textDecoration: 'none', boxShadow: 'none'}}>
                      <img src={PlusIcon} alt="Plus Icon" style={webStyles.image} />
                    </Button>
                  </Box>

                  <Box sx={{borderBottom:"1px solid #E2E8F0"}}>
                    <ToggleButtonGroup
                      value={this.state.selectedOption}
                      exclusive
                      onChange={this.handleOptionChange}
                      aria-label="chat options"
                      sx={webStyles.toggleButtonGroup}
                    >
                      <ToggleButton sx={webStyles.toggleButton} value="all" aria-label="all">
                        All
                      </ToggleButton>
                      <ToggleButton sx={webStyles.toggleButton} value="private" aria-label="private">
                        Private
                      </ToggleButton>
                      <ToggleButton sx={webStyles.toggleButton} value="group" aria-label="group">
                        Group
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>

                  <Box sx={webStyles.roomListContainer}>{this.renderRoomList()}</Box>
                  
              </Box>
              {this.renderAddRoomModal()}

              <Box style={{width:"100%"}}>
                <ViewChat id={""} navigation={this.props.navigation} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  pageContainer: {
    display: "flex",
    flexGrow: '1',
    maxWidth: "100%",
    padding: "0 40px",
    boxSizing: "border-box",
  },
  leftSideLayout: {
    maxWidth: "360px",
    width: "100%",
    height: "100%", 
    borderLeft: "1px solid #E2E8F0",
    borderRight: "1px solid #E2E8F0",
    padding: "6px 0px",
    backgroundColor: customTheme.palette.white.main,
    boxSizing: "border-box",
  },
  image: {
    width: "24px",
    height: "24px",
  },
  toggleButtonGroup: {
    borderRadius: '50px',
    backgroundColor: '#F8FAFC',
    padding: '4px',
    width: "fit-content",
    marginBottom: "16px",
  },
  toggleButton: {
    '&.MuiButtonBase-root': {
      fontWeight: 700,
      fontFamily: 'Open Sans',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#64748B',
      padding: '8px 12px',
      borderRadius: '50px',
      textTransform: 'none',
      '&.Mui-selected': {
        backgroundColor: '#EAE7DC',
        color: '#000',
      },
    },
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #E2E8F0",
    padding: "16px 24px",
    boxSizing: "border-box",
  },
  avatarCircle: {
    fontFamily: 'Open Sans',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    backgroundColor: '#EAE7DC99',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
    border: '2px solid #CBD5E1',
  },
  unreadCount: {
    fontFamily: 'Open Sans',
    backgroundColor: '#EAE7DC',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalButtonView: {
    marginLeft: 10,
  },
};
// Customizable Area End
