import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  activeTab?: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  drawerOpen: boolean;
  profile: any;
  activeMenu: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HeaderWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  signInCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      drawerOpen: false,
      profile: "",
      activeMenu: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      activeMenu: this.props.activeTab || "",
    });
    this.redirectInvalidAccount();
  }

  redirectInvalidAccount = async () => {
    const authToken = await getStorageData("token");
    const profile = await getStorageData("profile");
    this.setState({ profile });
    const allowedLoginRoutes = [
      "/LandingPage",
      "/Customisableuserprofiles",
      "/FriendsProfile",
      "/FriendsList",
      "/Settings",
      "/search",
      "/Cfargooglestreetviewinlaycontent"
    ];
    const currentPath = window.location.pathname;
    if (!authToken && allowedLoginRoutes.includes(currentPath)) {
      this.handleLogInNavigation("EmailAccountLoginBlock");
    }
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleLogInNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  // Customizable Area End
}
