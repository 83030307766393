import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End

export const configJSON = require("./config");
//@ts-ignore
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onVerify: (verified: boolean, verifyError: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  verified: boolean;
  //   recaptcha: ReCAPTCHA | null;
  key: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CaptchaController extends BlockComponent<Props, S, SS> {
  captcha: null;
  // Customizable Area Start
  captchaApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.captcha = null;
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      verified: false,
      key: "6LfIYj4qAAAAAA-UQ1cUW1h6YOySwHmzquW3_GZi",
      //@ts-ignore
      //recaptcha: React.createRef<ReCAPTCHA>(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.captchaApiCallId) {
        if (responseJson.data) {
          const { success, "error-codes": errorCodes = [] } = responseJson.data;
          this.props.onVerify(success, success ? null : errorCodes);
          if (!success) {
            this.reset();
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  recaptchaRef(e: any) {
    //@ts-ignore
    this.captcha = e;
  }

  onVerify(recaptchaResponse: any) {
    this.handleCaptchaApi(recaptchaResponse);
  }

  reset() {
    //@ts-ignore
    this.captcha.reset();
  }

  apiCallOrignal = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage)
    ,JSON.stringify(header));
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint);
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 
    method);
    body && type != "formData"? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body))
    : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
     body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleCaptchaApi = async (response: any) => {
    const formData = new FormData();
    formData.append("response", response);
    this.captchaApiCallId = await this.apiCallOrignal({
      method: "POST",
      endPoint: "account_block/accounts/verify_recaptcha",
      type: "formData",
      body: formData,
    });
  };
  // Customizable Area End
}
