import React from "react";

// Customizable Area Start
import CustomHeader from "../../..//components/src/CustomHeader.web";
// Customizable Area End

import HeaderWebController, { Props } from "./HeaderWebController";

export default class Header extends HeaderWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <CustomHeader
          navigation={this.handleLogInNavigation}
          activeMenu={this.state.activeMenu}
          drawerOpen={this.state.drawerOpen}
          handleMenuClick={this.handleMenuClick}
          toggleDrawer={this.toggleDrawer}
          isAuthenticated={true}
          profile={this.state.profile}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
