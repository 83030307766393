const CustomFontVariant: any = {
  palette: {
    font12400: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
    font12700: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: "Open Sans",
    },
    font14400: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
    font14700: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "Open Sans",
    },
    font16400: {
      fontSize: 16,
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
    font16700: {
      fontSize: 16,
      fontWeight: 700,
      fontFamily: "Open Sans",
    },
    font18400: {
      fontSize: 18,
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
    font18700: {
      fontSize: 18,
      fontWeight: 700,
      fontFamily: "Open Sans",
    },
    font20400: {
      fontSize: 20,
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
    font20700: {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "Open Sans",
    },
    font24400: {
      fontSize: 24,
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
    font24700: {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: "Open Sans",
    },
    font30400: {
      fontSize: 30,
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
    font30700: {
      fontSize: 30,
      fontWeight: 700,
      fontFamily: "Open Sans",
    },
  },
};

export default CustomFontVariant;
