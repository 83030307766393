import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isAction?: string;
  shareIcon: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  shareModalOpen: boolean;
  toastOpen: boolean;
  toastMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      shareModalOpen: false,
      toastOpen: false,
      toastMessage: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  copyUrl = async () => {
    const localStorageLat = await getStorageData("lat");
    const localStorageLng = await getStorageData("lng");
    const [baseUrl, queryString] = window.location.href.split("?");
    let params = new URLSearchParams(queryString);
    await navigator.clipboard.writeText("");
    params.delete("lat");
    params.delete("lng");
    params.delete("action");
    if (localStorageLat) params.set("lat", localStorageLat);
    if (localStorageLng) params.set("lng", localStorageLng);
    if (this.props.isAction) params.set("action", this.props.isAction);
    const currentUrl = `${baseUrl}?${params.toString()}`;
    await navigator.clipboard.writeText(currentUrl);
    this.setState({
      toastOpen: true,
      toastMessage: "Copied successfully!",
      shareModalOpen: false,
    });
  };

  ShareLinkViaEmail = async () => {
    const localStorageLat = await getStorageData("lat");
    const localStorageLng = await getStorageData("lng");
    const [baseUrl, queryString] = window.location.href.split("?");
    let params = new URLSearchParams(queryString);
    params.delete("lat");
    params.delete("lng");
    params.delete("action");
    if (localStorageLat) params.set("lat", localStorageLat);
    if (localStorageLng) params.set("lng", localStorageLng);
    if (this.props.isAction) params.set("action", this.props.isAction);
    const currentUrl = `${baseUrl}?${params.toString()}`;
    const subject = encodeURIComponent("Check out this link!");
    const bodyContent = encodeURIComponent(`Here is the link: ${currentUrl}`);
    const mailtoLink = `mailto:?subject=${subject}&body=${bodyContent}`;
    window.location.href = mailtoLink;
  };

  handleShareModal = () =>
    this.setState({ shareModalOpen: !this.state.shareModalOpen });

  handleCloseToast = () => this.setState({ toastOpen: false });
  // Customizable Area End
}
