export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const markerIcon = require("../assets/marker.png");
export const drop = require("../assets/drop.png");
export const star = require("../assets/star.png");
export const share = require("../assets/share.png");
export const addIcon = require("../assets/addIcon.png");
export const photo = require("../assets/photo.png");
export const unCheck = require("../assets/uncheck.png");
export const checked = require("../assets/checked.png");
export const dropBlack = require("../assets/dropblack.png");
export const copy = require("../assets/copy.png");
export const emailIcon = require("../assets/emailicon.png");
export const close = require("../assets/close.png");
export const unSelectedStar = require("../assets/unSelectedStar.png");