const customTheme: any = {
  palette: {
    white: {
      main: "#FFFFFF",
    },
    grey: {
      primary: "#EAE7DC",
      secondary: "#334155",
      main: "#475569",
      bold: "#0F172A",
      light: "#CBD5E1",
      extraLight:"#94A3B8",
      coolGrey:"#E2E8F0",
      mintGrey:"#F3F5F8",
      extraCoolGrey:"#64748B"
    },
    black: {
      primary: "#000000",
      secondary:"#0F172A"
    },
    red: {
      light: "#FEE2E2",
      primary: "#DC2626",
      secondary: "#F87171",
    },
    blue:{
      primary:"#6200EA",
    }
  },
};

export default customTheme;