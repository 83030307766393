import React from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { makeStyles, styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import customFontVariant from "./CustomFontVariant.web";
import customTheme from "./CustomTheme.web";
import { crossIcon } from "./assets.web";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    position: "relative",
    backgroundColor: customTheme.palette.white.main,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    padding: 40,
    borderRadius: "24px",
    maxWidth: 820,
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    top: "-1.0rem",
    right: "-1.0rem",
    cursor: "pointer",
    width: 36,
    height: 36,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40,
  },
}));

interface CustomModalProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  subtitle?: string;
  cancelButtonText?: string;
  doneButtonText?: string;
  onCancel?: () => void;
  onDone?: () => void;
  showCloseIcon?: boolean;
  onShowCloseIcon?: () => void;
  paperWidth?: string | number;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  title,
  subtitle,
  cancelButtonText = "Cancel",
  doneButtonText = "Done",
  onCancel,
  onDone,
  showCloseIcon = false,
  onShowCloseIcon,
  children,
  paperWidth = "100%",
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby="modal-title"
      aria-describedby="modal-content"
    >
      <Paper className={classes.paper} style={{width:paperWidth}}>
        {showCloseIcon && (
          <img
            src={crossIcon}
            alt="crossIcon"
            className={classes.closeButton}
            onClick={onShowCloseIcon}
          />
        )}
        {title && <Title>{title}</Title>}
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
        <div>{children}</div>
        {(onCancel || onDone) && (
          <div className={classes.buttonContainer}>
            {onCancel && (
              <ButtonCancel
                variant="outlined"
                onClick={onCancel}
                style={{ marginRight: "1rem" }}
              >
                {cancelButtonText}
              </ButtonCancel>
            )}
            {onDone && (
              <ButtonDone variant="contained" onClick={onDone}>
                {doneButtonText}
              </ButtonDone>
            )}
          </div>
        )}
      </Paper>
    </Modal>
  );
};

const ButtonDone = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...customFontVariant.palette.font16700,
  textTransform: "none",
});

const ButtonCancel = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  ...customFontVariant.palette.font16700,
  textTransform: "none",
});

const Title = styled(Typography)({
  ...customFontVariant.palette.font20700,
  color: customTheme.palette.black.primary,
});

const SubTitle = styled(Typography)({
  marginTop: 40,
  ...customFontVariant.palette.font24400,
  color: customTheme.palette.grey.secondary,
});

const Input = styled("input")(({ isError }: any) => ({
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

export default CustomModal;
