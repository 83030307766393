export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundImg = require("../assets/backgroundImg.png");
export const logoMain = require("../assets/logoMain.png");
export const facebook = require("../assets/facebook.png");
export const google = require("../assets/google.png");
export const right = require("../assets/right.png");
export const wrong = require("../assets/wrong.png");
export const checkbox = require("../assets/checkbox.png");
export const checkboxError = require("../assets/checkboxError.png");
export const eye = require("../assets/visibility.png");
export const eyeOff = require("../assets/visibility_off.png");
export const unchecked = require("../assets/unchecked.png");
